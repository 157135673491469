import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

import useImage from '../hooks/useImage';

const Avatar: FC<ComponentPropsWithoutRef<'img'> & { name: string }> = ({
  name,
  alt,
  src,
  className,
  ...props
}) => {
  const { onError, unavailable, src: newSrc } = useImage(src, true);

  return (
    <div
      className={clsx(
        className,
        'relative aspect-square h-full overflow-hidden rounded-full bg-gray-500 text-lg uppercase text-white'
      )}
      {...props}
    >
      {!unavailable && newSrc ? (
        <img
          alt={alt}
          className='absolute inset-0 z-10 h-full w-full object-cover'
          onError={onError}
          src={newSrc}
        />
      ) : undefined}
      <div className='absolute inset-0 flex items-center justify-center'>
        {name.split(' ').map((n) => n.charAt(0))}
      </div>
    </div>
  );
};

export default Avatar;
