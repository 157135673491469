import { useEffect, useState } from 'react';

const breakpoints = {
  sm: `(min-width: 640px)`,
  md: `(min-width: 768px)`,
  lg: `(min-width: 1024px)`,
  xl: `(min-width: 1280px)`,
  '2xl': `(min-width: 1536px)`,
};

const useBreakpoint = (breakpoint: keyof typeof breakpoints): boolean => {
  const [matches, setMatches] = useState(
    window.matchMedia(breakpoints[breakpoint]).matches
  );

  useEffect(() => {
    const matchMedia = window.matchMedia(breakpoints[breakpoint]);

    const handleChange = () => {
      setMatches(window.matchMedia(breakpoints[breakpoint]).matches);
    };

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [breakpoint]);

  return matches;
};

export default useBreakpoint;
