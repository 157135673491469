import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/700.css';
import '@fontsource/outfit/800.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { SWRConfig } from 'swr';

import App from './App';
import API from './api/API';
import './index.css';
import ErrorScreen from './routes/Error';

const rootEl = document.getElementById('root');

if (rootEl) {
  Sentry.init({
    dsn: import.meta.env.VITE_BO_SENTRY_DSN,
    environment: import.meta.env.PROD ? 'prod' : 'sandbox',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [import.meta.env.VITE_SENTRY_BO_URL],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
      new Sentry.TryCatch(),
    ],
    tracesSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });

  SuperTokens.init({
    appInfo: {
      apiDomain: import.meta.env.VITE_BO_API_URL,
      apiBasePath: '/auth',
      appName: 'Barbero',
      websiteDomain: import.meta.env.VITE_BO_URL,
    },
    recipeList: [Session.init({}), EmailPassword.init()],
  });

  const root = createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={({ error }) => (
            <ErrorScreen error={{ error: error.message, status: 500 }} />
          )}
        >
          <SWRConfig
            value={{
              fetcher: API.fetcher,
            }}
          >
            <SuperTokensWrapper>
              <App />
            </SuperTokensWrapper>
          </SWRConfig>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  throw new Error('Elemento raiz não encontrado.');
}
