/* eslint-disable */
import * as Sentry from '@sentry/react';

export interface APIError {
  error: string;
  status: number;
}

export interface APISuccessResponse {
  success: true;
}

export default class API {
  static async fetcher(
    url: string,
    options?: Omit<RequestInit, 'body'> & {
      body?: { [key: string]: any };
      retry?: boolean;
    }
  ): Promise<any> {
    if (options && options.method !== 'GET') {
      options = {
        ...options,
        headers: {
          ...options.headers,
        },
      };

      if (!(options.body instanceof FormData)) {
        options.headers = {
          ...options.headers,
          'Content-Type': 'application/json',
        };
      }
    }

    let res, data;

    try {
      res = await fetch(`${import.meta.env.VITE_BO_API_URL}${url}`, {
        credentials: 'include',
        mode: 'cors',
        ...options,
        body:
          options?.body instanceof FormData
            ? options.body
            : JSON.stringify(options?.body),
      });

      data = await res.json();
    } catch (ex) {
      if (ex instanceof Error && ex.name === 'TypeError' && ex.message === 'Load failed') {
        if (options?.retry) {
          throw {
            error: 'Sem conexão com a internet.',
            status: 400,
          };
        }

        return await API.fetcher(url, { ...options, retry: true });
      }

      Sentry.captureException(ex);

      throw {
        error: 'Ocorreu um erro ao contatar os servidores.',
        status: 400,
      };
    }

    if (!res.ok) {
      const error: APIError = {
        ...data,
        status: res.status,
      };

      throw error;
    }

    return data;
  }

  static async fetch<T extends { [key: string]: any }>(
    ...args: Parameters<typeof API.fetcher>
  ): Promise<[T, undefined] | [undefined, APIError]> {
    try {
      const data = await API.fetcher(...args);
      return [data, undefined];
    } catch (ex) {
      return [undefined, ex as APIError];
    }
  }
}
