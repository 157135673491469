export interface GetMeResponse {
  id: string;
  name: string;
  email?: string;
  profile_picture?: string;
  active_service?: {
    id: string;
    created_at: string;
    customer: {
      id: string;
      name: string;
      image?: string;
    };
  };
}

export interface GetSummaryResponse {
  avg_duration: number;
  total: number;
  services: {
    id: string;
    finished_at: string;
    duration: number;
    customer: {
      id: string;
      name: string;
      image?: string;
    };
  }[];
}

export default class UserAPI {
  static me = '/user/me';

  static summary = '/user/summary';
}
