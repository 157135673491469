import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

const IconButton: FC<
  ComponentPropsWithoutRef<'button'> & {
    icon: FC<ComponentPropsWithoutRef<'svg'>>;
    title: string;
  }
> = ({ icon: Icon, title, ...props }) => (
  <button
    aria-label={title}
    type='button'
    className={clsx(
      props.className,
      'cursor-pointer rounded p-2 hover:bg-gray-100 active:bg-gray-200 active:shadow-inner'
    )}
    {...props}
  >
    <Icon className='w-6' />
  </button>
);

export default IconButton;
