import * as Sentry from '@sentry/react';

import API, { APISuccessResponse } from './API';

export interface GetImageFromResponse {
  id: string;
}

export interface PostImageResponse {
  id: string;
  url: string;
}

export enum ImageType {
  PROFILE_PICTURE = 'PROFILE_PICTURE',
  FRONT = 'FRONT',
  LEFT = 'LEFT',
  BACK = 'BACK',
  RIGHT = 'RIGHT',
}

export const ImageTagTranslations = {
  PROFILE_PICTURE: 'Foto de Perfil',
  FRONT: 'Frente',
  LEFT: 'Esquerda',
  BACK: 'Atrás',
  RIGHT: 'Direita',
};

export default class ImageAPI {
  static get = (id?: string) =>
    id
      ? `${import.meta.env.VITE_BO_MINIO_URL}/${
          import.meta.env.VITE_BO_MINIO_BUCKET_IMAGES
        }/${id}.jpg`
      : undefined;

  static getFromCustomer = (id: string) =>
    `${import.meta.env.VITE_BO_API_URL}/image/customer/${id}`;

  static getFromUser = (id: string) =>
    `${import.meta.env.VITE_BO_API_URL}/image/user/${id}`;

  static requestUpload = (service: string, tag: ImageType) =>
    API.fetch<PostImageResponse>('/image', {
      method: 'POST',
      body: { service, tag },
    });

  static upload = async (
    url: string,
    file: File,
    retry = false
  ): Promise<{ success?: boolean; error?: string; status?: number }> => {
    let res;

    try {
      res = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'image/jpeg',
          'Cache-Control': 'max-age=31536000',
        },
      });
    } catch (ex) {
      if (
        ex instanceof Error &&
        ex.name === 'TypeError' &&
        ex.message === 'Load failed'
      ) {
        if (retry) {
          return {
            error: 'Sem conexão com a internet.',
            status: 400,
          };
        }

        return await ImageAPI.upload(url, file, true);
      }

      Sentry.captureException(ex);

      return {
        error: `Ocorreu um erro ao contatar os servidores. ${ex}`,
        status: 400,
      };
    }

    if (!res.ok) {
      return {
        error: 'Ocorreu um ao fazer o envio da foto.',
        status: 400,
      };
    }

    return { success: true };
  };

  static delete = (id: string) =>
    API.fetch<APISuccessResponse>(`/image/${id}`, {
      method: 'DELETE',
    });
}
