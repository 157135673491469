import { FC, ReactNode, Suspense } from 'react';

import Spinner from './Spinner';

const LazyRoute: FC<{ route: ReactNode }> = ({ route }) => (
  <Suspense
    fallback={
      <div className='flex h-full w-full items-center justify-center text-gray-900'>
        <Spinner size='xl' />
      </div>
    }
  >
    {route}
  </Suspense>
);

export default LazyRoute;
