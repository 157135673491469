import { ArrowLeftIcon, Bars3Icon } from '@heroicons/react/24/solid';
import { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import IconButton from './IconButton';

const AppBar: FC<{ children?: ReactNode; onOpen: () => void }> = ({
  children,
  onOpen,
}) => {
  const { state } = useLocation();
  const { from }: { from?: string } = state || {};
  const navigate = useNavigate();

  return (
    <div className='flex h-16 items-center gap-4 border-b border-gray-300 bg-white py-2 px-4'>
      {from ? (
        <IconButton
          icon={ArrowLeftIcon}
          onClick={() => navigate(-1)}
          title={`Voltar para ${from}`}
        />
      ) : undefined}
      <IconButton
        className='md:hidden'
        icon={Bars3Icon}
        onClick={onOpen}
        title='Abrir menu lateral'
      />
      {children}
    </div>
  );
};

export default AppBar;
