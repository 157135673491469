import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import barberoLogo from '../../assets/barbero.png';
import { APIError } from '../api/API';

export interface ErrorProps {
  error: APIError;
}

const Error: FC<ErrorProps> = ({ error }) => {
  const navigate = useNavigate();

  if (error.status === 404) {
    navigate('/nao-encontrado', { replace: true });
  }

  return (
    <div className='flex h-full flex-col items-center justify-center gap-2'>
      <img alt='Logo do Barbero' className='m-2 w-32' src={barberoLogo} />
      <h1 className='text-4xl font-bold'>Ocorreu um erro</h1>
      <p>{error.error || 'Erro desconhecido.'}</p>
    </div>
  );
};

export default Error;
