import { useMemo, useState } from 'react';

const useImage = (src?: string, thumb = false) => {
  const [errors, setErrors] = useState(0);

  const newSrc = useMemo(() => {
    if (!src) {
      return undefined;
    }

    if (errors === 0 && thumb) {
      if (src.includes(import.meta.env.VITE_BO_API_URL)) {
        return `${src}?thumb=1`;
      }

      return src.replace('.jpg', '_thumb.jpg');
    }

    if (errors === 2 || (errors === 1 && !thumb)) {
      if (src.includes(import.meta.env.VITE_BO_API_URL)) {
        return `${src}?raw=1`;
      }

      return src.replace(
        import.meta.env.VITE_BO_MINIO_BUCKET_IMAGES,
        import.meta.env.VITE_BO_MINIO_BUCKET_IMAGES_RAW
      );
    }

    return src;
  }, [errors, src, thumb]);

  if (!src) {
    return {
      onError: () => {},
      unavailable: true,
      src: undefined,
    };
  }

  return {
    onError: () => setErrors(errors + 1),
    unavailable: errors === 3 || (errors === 2 && !thumb),
    src: newSrc,
  };
};

export default useImage;
