import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';

const Spinner: FC<
  ComponentPropsWithoutRef<'div'> & { size?: 'md' | 'lg' | 'xl' }
> = ({ className, size = 'md', ...props }) => (
  <div
    aria-label='loading'
    role='status'
    className={clsx(
      'inline-block animate-spin rounded-full border-current border-t-transparent',
      {
        md: 'h-6 w-6 border-[3px]',
        lg: 'h-8 w-8 border-[4px]',
        xl: 'h-10 w-10 border-[5px]',
      }[size],
      className
    )}
    {...props}
  >
    <span className='sr-only'>Carregando...</span>
  </div>
);

export default Spinner;
