import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/session';

const Logout: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    navigate('/login', { replace: true });
  });

  return <></>;
};

export default Logout;
