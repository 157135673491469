import * as Sentry from '@sentry/react';
import { setUser, withSentryReactRouterV6Routing } from '@sentry/react';
import { FC, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import useSWR from 'swr';

import { APIError } from './api/API';
import ImageAPI from './api/ImageAPI';
import UserAPI, { GetMeResponse } from './api/UserAPI';
import AppBar from './components/AppBar';
import Avatar from './components/Avatar';
import LazyRoute from './components/LazyRoute';
import Nav from './components/Nav';
import Logout from './routes/auth/Logout';

const Forgot = lazy(() => import('./routes/auth/Forgot'));
const Login = lazy(() => import('./routes/auth/Login'));
const Recover = lazy(() => import('./routes/auth/Recover'));

const Customer = lazy(() => import('./routes/customer/Customer'));
const CustomerSearch = lazy(() => import('./routes/customer/CustomerSearch'));
const CustomerForm = lazy(() => import('./routes/customer/CustomerForm'));

const ServiceImages = lazy(
  () => import('./routes/service/images/ServiceImages')
);
const ServiceNew = lazy(() => import('./routes/service/new/ServiceNew'));
const Service = lazy(() => import('./routes/service/Service'));

const Home = lazy(() => import('./routes/home/Home'));

const NotFound = lazy(() => import('./routes/NotFound'));

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const ProtectedApp: FC = () => {
  const session = useSessionContext();
  const { data } = useSWR<GetMeResponse, APIError>(
    !session.loading && session.doesSessionExist ? UserAPI.me : undefined
  );
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setUser({
        id: data.id,
        email: data.email,
        username: data.name,
      });
    } else {
      setUser(null);
    }
  }, [data]);

  if (session.loading) {
    return <></>;
  }

  if (!session.doesSessionExist) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='flex min-h-full bg-gray-100 text-gray-900'>
      <Nav isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)} />
      <div className='flex-grow'>
        <AppBar onOpen={() => setDrawerOpen(true)}>
          {data ? (
            <Avatar
              alt='Foto do Usuário'
              className='ml-auto'
              name={data.name}
              src={ImageAPI.get(data.profile_picture)}
            />
          ) : (
            <div className='ml-auto aspect-square h-full animate-pulse rounded-full bg-gray-300' />
          )}
        </AppBar>
        <SentryRoutes>
          <Route path='servico'>
            <Route element={<LazyRoute route={<ServiceNew />} />} path='novo' />
            <Route path=':id'>
              <Route
                element={<LazyRoute route={<ServiceImages />} />}
                path='fotos'
              />
              <Route element={<LazyRoute route={<Service />} />} index />
            </Route>
          </Route>

          <Route path='clientes'>
            <Route element={<LazyRoute route={<CustomerSearch />} />} index />
            <Route
              element={<LazyRoute route={<CustomerForm />} />}
              path='novo'
            />
            <Route path=':id'>
              <Route element={<LazyRoute route={<Customer />} />} index />
              <Route
                element={<LazyRoute route={<CustomerForm />} />}
                path='editar'
              />
            </Route>
          </Route>

          <Route element={<LazyRoute route={<Home />} />} path='' />
          <Route element={<LazyRoute route={<NotFound />} />} path='*' />
        </SentryRoutes>
      </div>
    </div>
  );
};

const App: FC = () => {
  return (
    <SentryRoutes>
      <Route element={<LazyRoute route={<Login />} />} path='login' />
      <Route element={<LazyRoute route={<Logout />} />} path='sair' />
      <Route element={<LazyRoute route={<Forgot />} />} path='esqueci' />
      <Route element={<LazyRoute route={<Recover />} />} path='recuperar' />
      <Route element={<LazyRoute route={<ProtectedApp />} />} path='*' />
    </SentryRoutes>
  );
};

export default Sentry.withProfiler(App);
