import { Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  HomeIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import barberoLogo from '../../assets/barbero.png';
import useBreakpoint from '../hooks/useBreakpoint';

const NavItem: FC<{
  icon: FC<ComponentPropsWithoutRef<'svg'>>;
  to: string;
  name: string;
  onClose: () => void;
  className?: string;
}> = ({ icon: Icon, to, name, onClose, className }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      onClick={onClose}
      to={to}
      className={clsx(
        'group flex items-center gap-3 rounded p-2',
        {
          'bg-gray-200': isActive,
          'hover:bg-gray-100 active:bg-gray-200 active:shadow-inner': !isActive,
        },
        className
      )}
    >
      <Icon
        className={clsx('w-6', {
          'text-gray-400 group-hover:text-gray-900': !isActive,
          'text-gray-900': isActive,
        })}
      />
      <span>{name}</span>
    </Link>
  );
};

const Nav: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const md = useBreakpoint('md');

  return (
    <Transition
      as='nav'
      className='fixed inset-0 z-20 min-h-full w-full flex-shrink-0 md:relative md:w-64 md:bg-transparent'
      show={md || isOpen}
    >
      {!md ? (
        <Transition.Child
          as='button'
          className='absolute inset-0 h-full w-full bg-black opacity-50 md:hidden'
          enter='transition-opacity'
          enterFrom='opacity-0'
          enterTo='opacity-50'
          leave='transition-opacity'
          leaveFrom='opacity-50'
          leaveTo='opacity-0'
          onClick={onClose}
          type='button'
        />
      ) : undefined}
      <Transition.Child
        as='div'
        className='flex h-full w-64 flex-col gap-2 border-gray-300 bg-white p-3 md:border-r'
        enter='transition-transform'
        enterFrom='-translate-x-full'
        enterTo='translate-x-0'
        leave='transition-transform'
        leaveFrom='translate-x-0'
        leaveTo='-translate-x-full'
      >
        <img
          alt='Logo do Barbero'
          className='mx-auto w-24 py-4'
          src={barberoLogo}
        />
        <NavItem icon={HomeIcon} name='Home' onClose={onClose} to='/' />
        <NavItem
          icon={UserIcon}
          name='Clientes'
          onClose={onClose}
          to='/clientes'
        />
        <NavItem
          className='mt-auto'
          icon={ArrowRightOnRectangleIcon}
          name='Sair'
          onClose={onClose}
          to='/sair'
        />
      </Transition.Child>
    </Transition>
  );
};

export default Nav;
